import React from "react";
import styled from "styled-components";

import { language, palette, screens } from "../../../../utils";
import { ReactComponent as SectorIcon } from '../../../../assets/icons/sectorIcon.svg'
import { ReactComponent as RoundIcon } from '../../../../assets/icons/roundIcon.svg'
import { ReactComponent as HeartIcon } from '../../../../assets/icons/heartIcon.svg'
import doctorImg from '../../../../assets/img/doctorImg.jpg'
import BgWave from '../../../../assets/img/bg-wave.jpg'

export const Advantages = () => {
  return (
    <Container>
      <AdvantagesSection>
        <AdvHeading>
          {/*<AdvSectionName>Почему SnapDiet?</AdvSectionName>*/}
          <AdvTitle>Преимущества SnapDiet App</AdvTitle>
        </AdvHeading>

        <AdvList>
          <AdvItem>
            <AdvImgBorder>
              <AdvImgSubstrate>
                <AdvImgBg>
                  <AdvImgWrap><SectorIcon/></AdvImgWrap>
                </AdvImgBg>
              </AdvImgSubstrate>
            </AdvImgBorder>

            <AdvName>Простота использования</AdvName>
            <AdvDescription>Всего несколько нажатий — и данные о блюде уже на экране вашего смартфона.</AdvDescription>
          </AdvItem>

          <AdvItem>
            <AdvImgBorder>
              <AdvImgSubstrate>
                <AdvImgBg>
                  <AdvImgWrap><RoundIcon/></AdvImgWrap>
                </AdvImgBg>
              </AdvImgSubstrate>
            </AdvImgBorder>

            <AdvName>Точность</AdvName>
            <AdvDescription>Мы непрерывно совершенствуем алгоритмы приложения для максимально точной оценки состава блюд</AdvDescription>
          </AdvItem>

          <AdvItem>
            <AdvImgBorder>
              <AdvImgSubstrate>
                <AdvImgBg>
                  <AdvImgWrap><HeartIcon/></AdvImgWrap>
                </AdvImgBg>
              </AdvImgSubstrate>
            </AdvImgBorder>

            <AdvName>Удобство и поддержка</AdvName>
            <AdvDescription>Легко контролируйте ваше питание и достижение целей. А если что-то непонятно, мы всегда оперативно поможем!</AdvDescription>
          </AdvItem>
        </AdvList>
      </AdvantagesSection>


      <IsHereSection>
        <IsHereContent>
          <IsHereTextBlock>
            <IsHereHeading>Snapdiet</IsHereHeading>
            <IsHereTitle>Советы от экспертов
              по здоровому питанию</IsHereTitle>
            <IsHereSubtitle>Полезные рекомендации от профессионалов, которые помогут улучшить качество питания и поддерживать здоровье</IsHereSubtitle>
          </IsHereTextBlock>

          <IsHereImgM src={`/assets/ru/approved-L.png`} alt='approved'/>
          <IsHereImgM src={`/assets/ru/declined-L.png`} alt='declined'/>

          <IsHereImgL src={`/assets/ru/approved-L.png`} alt='approved'/>
          <IsHereImgL src={`/assets/ru/declined-L.png`} alt='declined'/>

          {/*<IsHereDrBlock>*/}
          {/*  <IsHereDrWords>“Помните о размерах порций и избегайте переедания. Обращайте внимание на сигналы голода и сытости, чтобы построить здоровые отношения с едой. До встречи в Snapdiet!”</IsHereDrWords>*/}
          {/*  <IsHereDoctor>*/}
          {/*    <IsHereDrAvatar src={doctorImg}/>*/}

          {/*    <IsHereDrInfo>*/}
          {/*      <DrName>Dr. Olivia Reynolds</DrName>*/}
          {/*      <DrPosition>Диетолог</DrPosition>*/}
          {/*    </IsHereDrInfo>*/}
          {/*  </IsHereDoctor>*/}
          {/*</IsHereDrBlock>*/}
        </IsHereContent>
      </IsHereSection>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const AdvantagesSection = styled.div`
  background: ${palette.bg.secondary};
  padding: 48px 16px 56px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: ${screens.tablet}) {
    padding: 60px 24px 80px;
    gap: 40px;
  }

  @media (min-width: ${screens.desktop}) {
    padding: 120px 0 160px;
    gap: 112px;
  }
`

const AdvHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: ${screens.tablet}) {
    align-items: center;
  }

  @media (min-width: ${screens.desktop}) {
    gap: 24px;
  }
`

const AdvSectionName = styled.p`
  color: ${palette.text.secondary};
  font-weight: 500;
  font-size: 0.875em;
  line-height: 18.23px;
  text-transform: uppercase;
  letter-spacing: .16em;
`

const AdvTitle = styled.h2`
  color: ${palette.text.secondary};
  font-weight: 700;
  font-size: 2em;
  line-height: 40px;
  letter-spacing: -0.018em;

  @media (min-width: ${screens.tablet}) {
    font-size: 2.25em;
    line-height: 48px;
    text-align: center;
    width: 600px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 3em;
    line-height: 62px;
    width: 730px;
  }
`

const AdvList = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  row-gap: 32px;

  @media (min-width: ${screens.tablet}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    row-gap: 0;
    column-gap: 16px;
  }

  @media (min-width: ${screens.desktop}) {
    width: 1440px;
    margin: 0 auto;
    column-gap: 20px;
  }
`

const AdvItem = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screens.tablet}) {
    align-items: center;
  }
`

const AdvImgBorder = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  padding: 1px;

  @media (min-width: ${screens.tablet}) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: ${screens.desktop}) {
    width: 112px;
    height: 112px;
  }
`

const AdvImgSubstrate = styled.div`
  background: ${palette.bg.secondary};
  border-radius: 20px;
  width: 100%;
  height: 100%;
`

const AdvImgBg = styled.div`
  background: rgba(255, 255, 255, .1);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AdvImgWrap = styled.span`
  width: 32px;

  @media (min-width: ${screens.tablet}) {
    width: 42px;
  }

  @media (min-width: ${screens.desktop}) {
    width: 46px;
  }
`

const AdvName = styled.h3`
  color: ${palette.text.secondary};
  font-weight: 700;
  font-size: 1.5em;
  line-height: 32px;
  letter-spacing: -1px;
  margin: 16px 0 12px;

  @media (min-width: ${screens.tablet}) {
    line-height: 30px;
    text-align: center;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 2em;
    line-height: 46px;
    margin: 40px 0 16px;
  }
`

const AdvDescription = styled.p`
  color: ${palette.text.secondary};
  line-height: 24px;

  @media (min-width: ${screens.tablet}) {
    text-align: center;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 1.125em;
    line-height: 32px;
    width: 420px;
  }
`

const IsHereSection = styled.div`
  background: ${palette.bg.tertiary};
  padding: 56px 16px;
  position: relative;

  &::before {
    @media (min-width: ${screens.tablet}) {
      content: "";
      height: 328px;
      width: 100%;
      background: ${palette.bg.tertiary};
      position: absolute;
      top: 0;
      z-index: -1;
    }

    @media (min-width: ${screens.desktop}) {
      height: 572px;
    }
  }

  &::after {
    @media (min-width: ${screens.tablet}) {
      content: "";
      background-image: url(${BgWave});
      background-size: cover;
      background-repeat: no-repeat;
      height: 228px;
      width: 100%;
      position: absolute;
      top: 328px;
      z-index: -1;
    }

    @media (min-width: ${screens.desktop}) {
      top: 572px;
      height: 14vw;
    }
  }

  @media (min-width: ${screens.tablet}) {
    padding: 80px 0;
    background: unset;
  }

  @media (min-width: ${screens.desktop}) {
    background-size: unset;
    padding: 160px 0;
  }
`

const IsHereContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  row-gap: 32px;

  @media (min-width: ${screens.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    padding: 0 16px;
  }

  @media (min-width: ${screens.desktop}) {
    width: 1440px;
    margin: 0 auto;
    column-gap: 20%;
    grid-template-rows: 414px 376px 0;
  }
`

const IsHereTextBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screens.tablet}) {
    grid-column: 1 / 2 span;
  }

  @media (min-width: ${screens.desktop}) {
    grid-column: 1;
    width: 588px;
  }
`

const IsHereHeading = styled.p`
  color: ${palette.text.tertiary};
  font-weight: 500;
  font-size: 0.875em;
  line-height: 18.23px;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: .16em;

  @media (min-width: ${screens.desktop}) {
    margin-bottom: 28px;
  }
`

const IsHereTitle = styled.h2`
  color: ${palette.text.primary};
  font-weight: 700;
  font-size: 2em;
  line-height: 40px;
  letter-spacing: -0.018em;
  margin-bottom: 16px;

  @media (min-width: ${screens.tablet}) {
    font-size: 2.25em;
    line-height: 48px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 3em;
    line-height: 60px;
    margin-bottom: 28px;
  }
`

const IsHereSubtitle = styled.p`
  color: ${palette.text.primary};
  line-height: 24px;
  opacity: .7;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.125em;
    line-height: 32px;
  }
`

const IsHereImgM = styled.img`
  width: 100%;

  @media (min-width: ${screens.desktop}) {
    display: none;
  }
`

const IsHereImgL = styled.img`
  display: none;

  @media (min-width: ${screens.desktop}) {
    display: block;
    width: 450px;
  }

  &:nth-child(4) {
    @media (min-width: ${screens.desktop}) {
      grid-row: 2 / 2 span;
      grid-column: 1;
    }
  }

  &:nth-child(5) {
    @media (min-width: ${screens.desktop}) {
      grid-row: 1 / 2 span;
      grid-column: 2;

    }
  }
`

const IsHereDrBlock = styled.div`
  display: none;

  @media (min-width: ${screens.tablet}) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-column: 1 / 2 span;
  }

  @media (min-width: ${screens.desktop}) {
    grid-column: 2;
    grid-row: 3;
    margin: 96px 0 0 82px;
    gap: 32px;
    width: 588px;
  }
`

const IsHereDrWords = styled.p`
  color: ${palette.text.primary};
  line-height: 24px;
  letter-spacing: -0.2px;
  font-style: italic;
  opacity: .7;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.125em;
    line-height: 30px;
  }
`

const IsHereDoctor = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

const IsHereDrAvatar = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 12px;
`

const IsHereDrInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const DrName = styled.p`
  color: ${palette.text.primary};
  font-size: 1.25em;
  font-weight: 700;
  line-height: 26.04px;
`

const DrPosition = styled.p`
  color: ${palette.text.subtitle};
  font-weight: 500;
  font-size: 0.875em;
  line-height: 21px;
`



