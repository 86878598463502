import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../utils";
// import { ReactComponent as TwitterIcon } from '../../assets/icons/twitterIcon.svg'
// import { ReactComponent as FacebookIcon } from '../../assets/icons/facebookIcon.svg'
// import { ReactComponent as InstagramIcon } from '../../assets/icons/instagramIcon.svg'
// import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedinIcon.svg'
import AppStore from '../../assets/img/AppStore.jpg'

export const Footer = () => {
  const year = new Date()

  return (
    <Container>
      <Navigation>
        {/*<NavBlock>*/}
          {/*<NavTitle>Продукт</NavTitle>*/}

          {/*/!*<NavItem>Ф</NavItem>*!/*/}
          {/*<NavItem>Цены</NavItem>*/}
          {/*<NavItem>Поддержка</NavItem>*/}
        {/*</NavBlock>*/}



        <NavBlock>
          <NavTitle>Юридическия информация</NavTitle>

          {/*<NavItem href={process.env.PUBLIC_URL + '/docs/consent_to_save_credentials_for_future_transactions.pdf'} target="_blank">Согласие на сохранение данных</NavItem>*/}
          <NavItem href={process.env.PUBLIC_URL + '/docs/consent_to_the_processing_of_personal_data.pdf'} target="_blank">Согласие на обработку персональных данных</NavItem>
            {/*<NavItem href={process.env.PUBLIC_URL + '/docs/agreement_for_use_of_paid_subscription.pdf'} target="_blank">Соглашение подписки</NavItem>*/}

        </NavBlock>

          <NavBlock>
          {/*/!*  <NavTitle>Компания</NavTitle>*!/*/}

          {/*/!*  <NavItem>О нас</NavItem>*!/*/}
          {/*/!*  <NavItem>Контакты</NavItem>*!/*/}
          </NavBlock>

          <NavBlock>
              <NavTitle>Контакты</NavTitle>

              <NavItem>support@snapdiet.app</NavItem>


              <NavItem><a href='https://t.me/snapdiet' target='_blank'>Telegram</a></NavItem>
          </NavBlock>

          <NavBlock>
              {/*<NavTitle>Компания</NavTitle>*/}

              {/*<NavItem>О нас</NavItem>*/}
              {/*<NavItem>Контакты</NavItem>*/}
          </NavBlock>

          <NavBlock>
              <NavTitle>Скачать приложениие</NavTitle>

              <a href='https://snapdiet.onelink.me/ZgWl/dvel0rut' target='_blank'>
                  <AppStoreImg src={AppStore}/>
              </a>
          </NavBlock>
      </Navigation>

      <Contacts>
        <Copyright>© {year.getFullYear()} Copyright, All Right Reserved</Copyright>

        {/*<Links>*/}
        {/*  <IconWrap><TwitterIcon/></IconWrap>*/}
        {/*  <IconWrap><FacebookIcon/></IconWrap>*/}
        {/*  <IconWrap><InstagramIcon/></IconWrap>*/}
        {/*  <IconWrap><LinkedinIcon/></IconWrap>*/}
        {/*</Links>*/}
      </Contacts>
    </Container>
  )
}

const Container = styled.div`
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 0 0 auto;

  @media (min-width: ${screens.tablet}) {
    padding: 60px 24px 34px;
    gap: 80px;
  }

  @media (min-width: ${screens.desktop}) {
    width: 1440px;
    margin: 0 auto;
    gap: 70px;
    padding: 100px 0 32px;
  }
`

const Navigation = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  row-gap: 28px;
  column-gap: 16px;

  @media (min-width: ${screens.tablet}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    row-gap: 0;
    column-gap: 0;
  }

  @media (min-width: ${screens.desktop}) {
    grid-template-columns: 144px 124px 226px 1fr 190px;
    column-gap: 100px;
  }
`

const NavBlock = styled.div`
  display: flex;
  flex-direction: column;

  // &:nth-child(1) {
  //   @media (min-width: ${screens.tablet}) {
  //     grid-column: 2;
  //   }
  // }
  //
  // &:nth-child(2) {
  //   @media (min-width: ${screens.tablet}) {
  //     grid-column: 4;
  //     margin-left: 70px;
  //   }
  //
  //   @media (min-width: ${screens.desktop}) {
  //     grid-column: 5;
  //     margin: 0;
  //   }
  // }
  //
  // &:nth-child(3) {
  //   @media (min-width: ${screens.tablet}) {
  //     grid-column: 1;
  //     grid-row: 1;
  //   }
  // }
  //
  // &:nth-child(4) {
  //   @media (min-width: ${screens.tablet}) {
  //     grid-column: 3;
  //     grid-row: 1;
  //   }
  // }
`

const NavTitle = styled.p`
  color: ${palette.text.primary};
  opacity: .7;
  font-size: 1em;
  line-height: 26px;
  letter-spacing: -0.1px;
  margin-bottom: 12px;

  @media (min-width: ${screens.tablet}) {
    margin-bottom: 20px;
  }
`

const NavItem = styled.a`
  color: ${palette.text.primary};
  font-size: 0.9em;
  line-height: 30px;
  letter-spacing: -0.2px;
  width: min-content;
  white-space: nowrap;
`

export const AppStoreImg = styled.img`
  width: 168px;

  @media (min-width: ${screens.desktop}) {
    width: 190px;
  }
`

const Contacts = styled.div`
  padding-top: 24px;
  border-top: 1px solid ${palette.bg.topSide};
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  @media (min-width: ${screens.tablet}) {
    padding-top: 32px;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }

  @media (min-width: ${screens.desktop}) {
    padding-top: 30px ;
  }
`

const Copyright = styled.p`
  color: ${palette.text.primary};
  opacity: .7;
  font-size: 0.9375em;
  line-height: 26px;
  letter-spacing: -0.1px;
`

const Links = styled.div`
  display: flex;
  gap: 32px;
`

const IconWrap = styled.a`
  width: 24px;
  height: 24px;
  opacity: .4;

  @media (min-width: ${screens.desktop}) {
    width: 18px;
    height: 18px;
  }
`
