import React, { useState } from "react";
import styled from "styled-components";

import { palette, screens } from "../../utils";
import { Button, Input } from "../../components";
// import biscuitImg from '../../../public/assets/biscuitImg.svg'
// import dishImg from '../../../public/assets/dishImg.png'
import BgFlowers from '../../assets/img/bg-flowers.jpg'

import {Advantages, GetStarted, Subscription} from "./components";
import AppStore from "../../assets/img/AppStore.jpg";
import {AppStoreImg} from "../../components/Footer/Footer";

const path = window.location.pathname;

export const Main = () => {


    const companyes = {
        one: {
            title: <Title>Анализируйте состав блюд по фото и <GreenTitle>улучшайте свои пищевые привычки</GreenTitle></Title>,
            GetStarted: true,
            Advantages: true,
            footer: <></>,
            startBtn: true
        },
        // two: {
        //     title: <Title>Работа с нутрициологом онлайн: <GreenTitle>похудеть за 3 месяца</GreenTitle></Title>,
        //     GetStarted: true,
        //     Advantages: true,
        //     startBtn: true,
        //     footer: <>
        //        </>
        // },
        // week: {
        //     title: <Title>Работа с нутрициологом онлайн: <GreenTitle>похудеть за неделю</GreenTitle></Title>,
        //     GetStarted: false,
        //     Advantages: false,
        //     startBtn: false,
        //     footer: <>
        //             </>
        // },
        // free: {
        //     title: <Title>Работа с нутрициологом онлайн: <GreenTitle>похудеть реально!</GreenTitle></Title>,
        //     GetStarted: false,
        //     Advantages: false,
        //     startBtn: false,
        //     footer: <>
        //         {/*<ContactTitle style={{fontSize: 24, marginBottom: 0}}>17 990₽ / 1 месяц (Скидка 10%)</ContactTitle>*/}
        //         {/*<ContactSubtitle style={{fontSize: 23, marginBottom: 16, textDecoration: 'line-through'}}>19 990₽ / 1 месяц </ContactSubtitle>*/}
        //     </>
        // },
    }


    let company = companyes.one;

    // if (path.includes('main')) {
    //     company = companyes.one;
    // }

    // if (path.includes('week')) {
    //     company = companyes.week;
    // }
    //
    // if (path.includes('diet')) {
    //     company = companyes.free;
    // }

  return (
    <Container>
      <Section>
        <TextBlock>
          {/*<Heading>Добро пожаловать в SnapDiet!</Heading>*/}
            {company.title}
          <Subtitle>SnapDiet поможет наладить здоровое питание и следить за прогрессом</Subtitle>

            {company.startBtn && <ButtonWrap>

                {/*<Button onClick={() => {*/}
                {/*    document.querySelector('#section1')?.scrollIntoView({*/}
                {/*        behavior: 'smooth'*/}
                {/*    });*/}
                {/*}}>Начать</Button>*/}

                <a

                    href='https://snapdiet.onelink.me/ZgWl/9cyaddjs' target='_blank' style={{background: '#55C65C',
                    borderRadius: 25,
                    color: '#fff',
                    fontWeight: '600',
                    fontSize: 19,
                    width:  230,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px 0'
                }}

                >Скачать в AppStore</a>

                {/*<a style={{    position: 'absolute',*/}
                {/*    top: 10,*/}
                {/*    right: 10,}} href='https://apps.apple.com/ru/app/snapdiet-app/id6498787212' target='_blank'>*/}
                {/*    <AppStoreImg src={AppStore}/>*/}
                {/*</a>*/}
            </ButtonWrap>}
        </TextBlock>

          <Cover>
              <PhoneImg fetchPriority="high" src='/assets/ru/phone.png' alt='phone'/>
              <DishImg fetchPriority="high" src='/assets/dishImg.png' alt='dish'/>
          <BiscuitImg fetchPriority="high"  src='/assets/biscuitImg.png' alt='biscuit'/>
        </Cover>
      </Section>

        {company.GetStarted && <GetStarted/>}
        {company.Advantages && <Advantages/>}
      {/*<Subscription/>*/}

      <Contact>
        <ContactHeading>
          <ContactSectionName id="section2">присоединяйтесь</ContactSectionName>
            <ContactTitle style={{fontSize: 48, marginBottom: 10, maxWidth: 450, textAlign: 'center', lineHeight: '55px', fontWeight: 600}}>
                Быстрый анализ блюд по фото
            </ContactTitle>
            <ContactSubtitle style={{fontSize: 23, marginBottom: 16}}> ...и ежедневный контроль своего рациона</ContactSubtitle>
             {/*<ContactSubtitle><b>с образованием</b> врача-диетолога</ContactSubtitle>*/}
        </ContactHeading>

        <Form>
        {/*    {!isSend &&*/}
        {/*        <Input*/}
        {/*            value={name}*/}
        {/*            placeholder='Ваш телефон'*/}
        {/*            onChange={(e) => setName(e.target.value)}*/}
        {/*        />}*/}

          <FormButtonWrap><ButtonA href='https://snapdiet.onelink.me/ZgWl/0fdrp4sa' target='_blank'>Скачать в AppStore</ButtonA></FormButtonWrap>
        </Form>
      </Contact>
    </Container>
  )
}


const ButtonA = styled.a`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  border: none;
  border-radius: 40px;
  background: ${palette.bg.secondary};
  color: #fff !important;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 27px;

  &:hover {
    transition: .3s;
    filter: brightness(0.9);
      opacity: 1;
  }
`


const Container = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  gap: 32px;
  padding-bottom: 40px;

  @media (min-width: ${screens.tablet}) {
    padding-bottom: 50px;
  }

  @media (min-width: ${screens.desktop}) {
    padding: 0;
    gap: 78px;
  }
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 0;
  width: 342px;

  @media (min-width: ${screens.tablet}) {
    padding: 48px 0 0;
  }

  @media (min-width: ${screens.desktop}) {
    padding-top: 76px;
  }
`

const Heading = styled.p`
  color: ${palette.text.tertiary};
  font-weight: 500;
  font-size: 0.875em;
  line-height: 18.23px;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: .1em;

  @media (min-width: ${screens.tablet}) {
    margin-bottom: 12px;
  }

  @media (min-width: ${screens.desktop}) {
    margin-bottom: 16px;
  }
`

const Title = styled.h1`
  color: ${palette.text.primary};
  font-weight: 700;
  font-size: 2.25em;
  line-height: 40px;
  text-align: center;
  margin-bottom: 16px;
  letter-spacing: -0.018em;

  @media (min-width: ${screens.tablet}) {
    font-size: 3.125em;
    line-height: 60px;
    width: 400px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 4em;
    line-height: 76px;
    letter-spacing: -0.024em;
    width: 920px;
    margin-bottom: 24px;
  }
`

const GreenTitle = styled.span`
  color: ${palette.text.tertiary};
`

const Subtitle = styled.p`
  color: ${palette.text.primary};
  font-size: 1.125em;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: -0.01em;

  @media (min-width: ${screens.tablet}) {
    width: 474px;
    line-height: 26px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 1.25em;
    line-height: 28px;
    margin-bottom: 32px;
    width: 900px;
  }
`

const ButtonWrap = styled.div`
    width: 225px;
    flex-direction: column;
    gap: 15px;
    display: flex;
    justify-content: center;

    @media (min-width: ${screens.tablet}) {
        width: 495px;
        flex-direction: row;

    }

    @media (min-width: ${screens.desktop}) {
        width: 495px;
        flex-direction: row;

    }
    
`

const Cover = styled.div`
  position: relative;
`

const PhoneImg = styled.img`
  width: 476px;
  margin-left: -26px;
  animation: moveUpDown 3s infinite alternate ease-out;

  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-30px);
    }
  }

  @media (min-width: ${screens.desktop}) {
    width: 840px;
    margin-left: -50px;

    @keyframes moveUpDown {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-50px);
      }
    }
  }
`

const BiscuitImg = styled.img`
  width: 80px;
  position: absolute;
  right: -40px;
  bottom: 116px;
  display: none;

  @media (min-width: ${screens.tablet}) {
    display: block;
  }

  @media (min-width: ${screens.desktop}) {
    width: 168px;
    bottom: 204px;
    right: -102px;
  }
`

const DishImg = styled.img`
  width: 90px;
  position: absolute;
  left: -66px;
  top: 68px;
  display: none;

  @media (min-width: ${screens.tablet}) {
    display: block;
  }

  @media (min-width: ${screens.desktop}) {
    width: 138px;
    top: 120px;
    left: -130px;
  }
`

const Contact = styled.div`
  background-image: url(${BgFlowers});
  padding: 80px 16px;
  background-position: center;
  display: flex;
  flex-direction: column;
  gap: 20px;


  @media (min-width: ${screens.tablet}) {
    padding: 80px 110px 100px;
    margin: 0 24px;
    border-radius: 24px;
    background-size: cover;
    gap: 24px;
  }

  @media (min-width: ${screens.desktop}) {
    margin: 0 40px;
    padding: 100px 0 132px;
  }
`

const ContactHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContactSectionName = styled(Heading)`
  color: ${palette.text.secondary};
`

const ContactTitle = styled.h2`
  color: ${palette.text.secondary};
  font-weight: 700;
  font-size: 2em;
  line-height: 29px;
  letter-spacing: -0.018em;
  margin-bottom: 16px;

  @media (min-width: ${screens.tablet}) {
    font-size: 2.25em;
    line-height: 48px;
    margin-bottom: 12px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 3em;
    line-height: 60px;
  }
`

const ContactSubtitle = styled.p`
  color: ${palette.text.secondary};
  line-height: 24px;
  text-align: center;

  @media (min-width: ${screens.tablet}) {
    width: 400px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 1.125em;
    line-height: 28px;
    letter-spacing: -0.2px;
    width: 450px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${screens.tablet}) {
    width: 500px;
    margin: 0 auto;
  }
`

const FormButtonWrap = styled.div`
  width: 100%;

  @media (min-width: ${screens.tablet}) {
    margin-top: 4px;
  }
`
